var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "confirmBody" }, [
    _c(
      "div",
      { staticClass: "contentBody" },
      [
        _c("el-row", { staticClass: "ellipsisContent title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _vm.imgPath
          ? _c("el-row", { staticStyle: { "margin-top": "31px" } }, [
              _c("img", { attrs: { src: _vm.getImg } }),
            ])
          : _vm._e(),
        _c("el-row", { staticClass: "ellipsisContent desribe" }, [
          _vm._v(" " + _vm._s(_vm.describeText) + " "),
        ]),
        _c("el-row", { staticStyle: { "margin-top": "30px" } }, [
          _c(
            "button",
            {
              staticClass: "cancelButton",
              staticStyle: { float: "left" },
              on: { click: _vm.cancel },
            },
            [_vm._v(_vm._s(_vm.cancelButtonText))]
          ),
          _c(
            "button",
            {
              staticClass: "confirmButton",
              staticStyle: { float: "right" },
              on: { click: _vm.confirm },
            },
            [_vm._v(_vm._s(_vm.confirmButtonText))]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }