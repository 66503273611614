<template>
    <div class="confirmBody">
        <div class="contentBody">
            <el-row class="ellipsisContent title">
                {{title}}
            </el-row>
            <el-row v-if="imgPath" style="margin-top:31px">
                <img :src="getImg" />
            </el-row>
            <el-row class="ellipsisContent desribe">
                {{describeText}}
            </el-row>
            <el-row style="margin-top:30px">
                <button class="cancelButton" style="float:left" @click="cancel">{{cancelButtonText}}</button>
                <button class="confirmButton" style="float:right" @click="confirm">{{confirmButtonText}}</button>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
	props: ["title", "describeText", "imgPath", "cancelButtonText", "confirmButtonText", "confirmVal"],
	methods: {
		cancel() {
			this.$emit("cancel", this.confirmVal)
		},
		confirm() {
			this.$emit("confirm", this.confirmVal)
		}
	},
	computed: {
		getImg() {
			return require("../assets/" + this.imgPath)
		}
	}

}
</script>

<style lang="less" scoped>
.confirmBody {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0px;
    position: fixed;
    top: 0;
    left: 0;
}
.contentBody {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 440px;
    // height: 360px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.16);

    .title {
        font-size: 22px;
        font-weight: bold;
        color: #333333;
        -webkit-line-clamp: 2;
    }

    .desribe {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 400;
        color: #757575;
        -webkit-line-clamp: 3;
    }
}
</style>
